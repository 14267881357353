<template>
	<div class="modal-card" style="width: auto">
		<header class="modal-card-head">
			<p class="modal-card-title">Impresión de la orden {{ data.simple_order_new }}</p>
		</header>
		<section class="modal-card-body">
			<div class="columns is-multiline">
				<div class="column is-12">
					<b-field grouped>
						<b-field label="Tipo de impresion" style="min-width: 150px">
							<b-select v-model="params.type" expanded>
								<option v-for="item in options"
										:value="item.value">
									{{ item.text }}
								</option>
							</b-select>
						</b-field>
						<div class="field" v-if="false">
							<b-checkbox v-model="params.ticketx">
								Como ticket
							</b-checkbox>
						</div>
						<template v-if="hasCompany">
							<div class="field" v-if="!company.general_public">
								<b-checkbox v-model="params.worksheet">
									Hoja de trabajo
								</b-checkbox>
							</div>
						</template>
						<div class="field">
							<b-checkbox v-model="params.send_email">
								Enviar por correo
							</b-checkbox>
						</div>
					</b-field>
				</div>
				<div class="column is-12">
					<b-field>
						<div class="field">
							<b-checkbox v-model="params.order">
								Hoja de Orden
							</b-checkbox>
						</div>
						<b-field label="Copias" v-if="params.printerManager !== $lapi_printer_managers.VFP">
							<b-numberinput
								:disabled="!params.order"
								:min="0"
								expanded
								v-model="params.copies">
							</b-numberinput>
						</b-field>

					</b-field>
				</div>
				<div class="column is-12">
					<div class="block">
						<b-radio v-model="params.type_labels"
								 @input="deactivateSamples"
								 native-value="none">
							Ninguna
						</b-radio>
						<b-radio v-model="params.type_labels"
								 @input="activeSamples"
								 native-value="both">
							Ambas
						</b-radio>
						<b-radio v-model="params.type_labels"
								 @input="deactivateSamples"
								 native-value="doc">
							Documentos
						</b-radio>
						<b-radio v-model="params.type_labels"
								 @input="activeSamples"
								 native-value="labels">
							Recipientes
						</b-radio>
					</div>
				</div>
				<div class="column is-12">
					<div class="is-divider" data-content="Muestras"></div>
				</div>
				<div class="column is-3">
					<b-field label="Para documentos">
						<b-numberinput
							:min="0"
							style="max-width: 200px"
							:disabled="params.type_labels === 'labels' || params.type_labels === 'none'"
							v-model="params.label_doc_copies">
						</b-numberinput>
					</b-field>
          </div>
        <div class="column is-3" v-if="$alfalab.enableConfigureContainerLabels(this)">
          <b-field label="Para recipientes">
            <b-numberinput
                :min="0"
                style="max-width: 200px"
                :disabled="params.type_labels === 'doc' || params.type_labels === 'none'"
                v-model="params.label_container_copies">
            </b-numberinput>
          </b-field>
				</div>
        <div class="column is-3" v-if="(company && company.company)">
          <b-field label="Para espirometría">
            <b-numberinput
                :min="0"
                style="max-width: 200px"
                :disabled="params.type_labels === 'doc' || params.type_labels === 'none'"
                v-model="params.label_spiro_copies">
            </b-numberinput>
          </b-field>
        </div>
        <div class="column is-3" v-if="(company && company.company)">
          <b-field label="Para éxamen médico">
            <b-numberinput
                :min="0"
                style="max-width: 200px"
                :disabled="params.type_labels === 'doc' || params.type_labels === 'none'"
                v-model="params.label_examed_copies">
            </b-numberinput>
          </b-field>
        </div>
				<div class="column is-12">
					<div class="is-divider" data-content="Muestras"></div>
				</div>
        <div class="is-flex is-flex-wrap-wrap">
          <div class="" v-for="(sample, key) in samples" style="margin: 10px;">
            <div class="sample-box">
              <b-field class="is-center" style="text-align: center">
                <b-checkbox v-model="params.samples[key]"
                            :true-value="sample.id"
                            :disabled="params.type_labels === 'none' || params.type_labels === 'doc'"
                            :false-value="null">
                  {{ sample.container_shortname }}
                </b-checkbox>
                <br/>
                <div style="display: flex">
                  <b-tooltip multilined position="is-right">
                    <div style="font-size: 9px; margin-right: 20px">
                      <span v-if="data.samples[key].studies" v-html="data.samples[key].studies.shortnames.replaceAll(',', '<br>')"></span>
                    </div>
                    <template v-slot:content>
                  <span
                      v-if="data.samples[key].studies"
                      v-html="data.samples[key].studies.names.replaceAll(',', '<br>') "/>
                    </template>
                  </b-tooltip>
                  <div><img :alt="data.samples[key].container" :src="data.samples[key].image" height="100px" style="height: 100px"/></div><br />
                </div>
                {{ data.samples[key].sample_material }} <br/>
              </b-field>
            </div>
          </div>
        </div>
			</div>
		</section>
		<footer class="modal-card-foot">
			<button class="button" type="button" @click="$parent.close()">
				Cerrar
			</button>
			<button class="button is-primary" type="button" @click="onPrint(data.order_new)">
				<b-icon icon="print"></b-icon>
				<span>Imprimir</span>
			</button>

<!--			<button class="button is-primary" type="button" @click="onPrint(data.order_new, true)">-->
<!--				<b-icon icon="print"></b-icon>-->
<!--				<span>Imprimir y limpiar</span>-->
<!--			</button>-->
			<button class="button" type="button" @click="onResendOrder(data.order_new)">
				<b-icon icon="envelope"></b-icon>
				<span>Reenviar Correo y WhatsApp de Hoja de Orden</span>
			</button>
      <button class="button"
              v-if="$auth.user().rol === 'ADMIN'"
              type="button" @click="onPreview(data.order_new)">
        <b-icon icon="eye"></b-icon>
        <span>Vista previa de las impresiones</span>
      </button>
		</footer>
		<b-modal :active.sync="modalPrintJobActive">
			<modal-print-job :data="order" :has-data="modalPrintJobActive" :printer-manager="params.printerManager"></modal-print-job>
		</b-modal>
    <b-modal :active.sync="modalPreviewPrint">
      <modal-preview-print :data="preview"/>
    </b-modal>
	</div>
</template>

<script>

import ModalPrintJob from "@/components/print/ModalPrintJob";
import ModalPreviewPrint from "@/components/print/ModalPreviewPrint";

export default {
	name: "ModalPrint",
	components: {ModalPrintJob, ModalPreviewPrint},
	props: {
		data: Object,
		hasCompany: Boolean,
		company: Object
	},
	data() {
		return {
			options: [
				{value: 'normal', text: 'Normal'}
			],
			params: {
				ticket: false,
				copies: 2,
				type: 'normal',
				type_labels: 'both',
				label_doc_copies: 1,
        label_container_copies: 1,
        label_examed_copies: this.company && this.company.company ? this.company.labels_spiro: 3,
        label_spiro_copies: this.company && this.company.company ? this.company.labels_examed: 4,
				worksheet: false,
				order: false,
				send_email: false,
				samples: [],
        printerManager: '',
        printer: 1
			},
			samples: [],
			labels: [],
			type: 'both',
			order: {},
			modalPrintJobActive: false,
			canPrint: false,
      preview: {},
      modalPreviewPrint: false,
		}
	},
	created() {
		this.samples = this.data.samples || [];
		this.activeSamples();
		if (this.hasCompany) {
      if(!this.$alfalab.isFastMode(this.$auth.user())){
        this.params.worksheet = !this.company.general_public;
      }
      if(this.$alfalab.isDefaultNoWorkSheet(this.$auth.user())){
        this.params.worksheet = false;
        this.params.order = false;
      }
		}
		this.params.printerManager = localStorage.getItem('printerManager') || this.$lapi_printer_managers.jsprint;
		this.params.printer = Number.parseInt(localStorage.getItem('printerManager_printer')) || 1;
	},
  mounted() {
    //console.log('mounted modal print');
    if(this.$alfalab.isFastMode(this.$auth.user())){
      setTimeout(()=> {
        this.$loader.show();
      }, 250)
      this.onPrint(this.data.order_new, true);
    }
  },
  methods: {
		async onPrint(order = null, close = false) {
			this.$loader.show();
			const response = await this.$http.get('/print-order/' + order, {
				params: this.params
			})
				.catch(({response}) => {
					let data = response.data || {};
					return response;
				});

			if (response.status === 200) {
				this.order = response.data || {};
				this.modalPrintJobActive = true;
			} else {
				this.$toast.error(response.data.message);
			}
			this.$loader.hide();
      if(close){
        this.$emit('close-print-modal', true);
      }
		},
		activeSamples() {
			this.params.samples = [];
			if(this.$alfalab.selectAllSamplesToPrint(this.$auth.user())){
        this.samples.forEach(el => {
          this.params.samples.push(el.id);
        });
      }
		},
		deactivateSamples() {
			this.params.samples = [];
		},
		async onResendOrder(order)
		{
			this.$loader.show();
			const response = await this.$http.get(`/orders/${order}/send-mail`).catch(({response}) => {
				let data = response.data || {};
				return response;
			});

			if (response.status === 200) {
				this.order = response.data || {};
				this.$toast.success(response.data.message);
			} else {
				this.$toast.error(response.data.message);
			}
			this.$loader.hide();
		},
    async onPreview(order){
      this.$loader.show();
      const response = await this.$http.get(`/print-order/${order}/preview`, {
        params: this.params
      }).catch(({response}) => {
        let data = response.data || {};
        return response;
      });

      if (response.status === 200) {
        this.preview = response.data || {};
        this.modalPreviewPrint = true;
      } else {
        this.$toast.error(response.data.message);
      }
      this.$loader.hide()
    },
    renderTooltipDates(sample){
      return `${sample.date1 !== '0001-01-01 00:00:00' && sample.date1 !== '0001-00-00 00:00:00' ? 'Recibida en sucursal<br>': ''}` +
          `${sample.date2 !== '0001-01-01 00:00:00' && sample.date2 !== '0001-00-00 00:00:00' ? 'Salio de Sucursa: '  + sample.date2  + '<br>': ''}` +
          `${sample.date3 !== '0001-01-01 00:00:00' && sample.date3 !== '0001-00-00 00:00:00' ? 'Recibida en centrifugación: '  + sample.date3  + '<br>': ''}` +
          `${sample.is_complete ? 'Concluida': ''}`;
    }
	}
}
</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";

.sample-box {
	display: inline-block;
	border: $primary solid 1px;
	padding: 0.25rem;

	.field.has-addons {
		display: inline-block;

		.icon {
			margin: 2rem 0;
			display: inline-block;
			width: 100%;
			height: auto;
			text-align: center;

			i {
				font-size: 4rem;
			}
		}
	}
}
</style>
